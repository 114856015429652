import { RouteObject } from 'react-router';

import Authenticated from 'src/components/Authenticated';
import { Navigate } from 'react-router-dom';

import AccentSidebarLayout from 'src/layouts/AccentSidebarLayout';

import dashboardsRoutes from './dashboards';
import blocksRoutes from './blocks';
import applicationsRoutes from './applications';
import managementRoutes from './management';
import accountRoutes from './account';
import { Suspense, lazy } from 'react';

import SuspenseLoader from 'src/components/SuspenseLoader';
import helpRoutes from './help';
import BaseLayout from 'src/layouts/BaseLayout';
import baseRoutes from './base';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Dashboards

const StatusNeedApproval = Loader(
  lazy(() => import('src/content/pages/Status/NeedApproval'))
);

const PrivacyPolicy = Loader(
  lazy(() => import('src/content/pages/Policy/privacy'))
);

const router: RouteObject[] = [
  {
    path: 'account',
    children: accountRoutes
  },

  // Accent Sidebar Layout

  {
    path: '',
    element: (
      <Authenticated>
        <AccentSidebarLayout />
      </Authenticated>
    ),
    children: [
      {
        path: '',
        element: <Navigate to="dashboards/FinancialServices" replace />
      },
      {
        path: 'needapproval',
        element: <StatusNeedApproval />
      },
      {
        path: 'dashboards',
        children: dashboardsRoutes
      },
      {
        path: 'blocks',
        children: blocksRoutes
      },
      {
        path: 'applications',
        children: applicationsRoutes
      },
      {
        path: 'management',
        children: managementRoutes
      },
      {
        path: 'help',
        children: helpRoutes
      }
    ]
  }
];

export default router;
