import { AxiosResponse } from 'axios';
import { useNavigate } from 'react-router-dom';
import { AuthResponse } from 'src/models/response/AuthResponse';
import {
  EmailSyncFilter,
  UserEmailPreferences
} from 'src/models/response/GetEmailFilterResponse';
import {
  reviewPreference,
  SaveWebsitesUrlsResponse
} from 'src/models/response/GetWebsitesTypesResponse';
import { PaymentUpdateResponse } from 'src/models/response/PaymentResponse';
import { SubscriptionResoponse } from 'src/models/response/subscriptionResponse';
import { Workspace } from 'src/models/response/WorkspaceReponse';
import { User, UserRole } from 'src/models/user';
import { StorageKeys } from 'src/services/apiService';
import { AuthService } from 'src/services/authService';
import { EmailService } from 'src/services/emailService';
import { GmailService } from 'src/services/gmailService';
import { ReviewService } from 'src/services/reviewService';
import { userService } from 'src/services/userService';
import create from 'zustand';
import { devtools, persist } from 'zustand/middleware';
import { useDashboardStore } from './dashboardStore';
import { useEmailStore } from './emailStore';
import { useReviewDashboardStore } from './reviewDashboardStore';
import {
  useAmazonReviewStore,
  useEbayReviewStore,
  useTrustpilotReviewStore,
  useGoogleReviewStore
} from './reviewStore';
import { useTemplateStore } from './templateStore';
import { useUsersStore } from './usersStore';

export interface AuthState {
  outlookmailErrorForMailIdAlreadyInUse: string;
  gmailErrorForMailIdAlreadyInUse: string;
  microsoftCallBackStatus: string;
  googleCallBackStatus: string;
  subscriptionExpired: boolean;
  isInitialized: boolean;
  isAuthenticated: boolean;
  isEbayAuthenticated: boolean;
  isGorgiasAuthenticated: boolean;
  isGoogleReviewAuthenticated: boolean;
  token: string;
  refreshToken: string;
  loading: boolean;
  errorText: { data: string } | null;
  resetPassError: string;
  registerError: string;
  microsoftError: string;
  userEmail: string | null;
  userName: string | null;
  accountProvider: string | null;
  preferSourceType: string | number | null;
  preferSourceTypeError: string;
  isTypeLoaded: boolean;
  previousSource: string | number | null;
  requestedLocation: string | null;

  outlookUserpreferences: UserEmailPreferences;
  emailSyncFilter: EmailSyncFilter;
  savingOutlookPreferences: boolean;
  savingEmailSyncFilter: boolean;
  signature: string;

  gmailUserpreferences: UserEmailPreferences;
  gmailSyncFilter: EmailSyncFilter;
  savingGmailPreferences: boolean;
  savingGmailSyncFilter: boolean;
  gmailSignature: string;

  //#region reviews
  reviewsPreferences: SaveWebsitesUrlsResponse;
  getReviewPreferences: () => void;
  saveReviewPreferences: () => void;
  savingReviewPreferences: boolean;
  trustpiloturls: reviewPreference[];
  amazonurls: reviewPreference[];
  ebayUsers: reviewPreference[];
  googleReviews: reviewPreference[];
  updateSavingReviewPreferences: (value: boolean) => void;
  //#endregion

  user: User;
  // actions
  login: (userName: string, password: string) => void;
  logout: () => void;
  clearStores: () => void;
  setToken: (token: string, refreshToken: string) => void;
  microsoftSignIn: (
    code: string,
    state: string,
    alredyLoggedIn: boolean
  ) => void;
  googleSignIn: (code: string, state: string, alredyLoggedIn: boolean) => void;
  ebaySignIn: (code: string, state: string) => void;
  gorgiasSignIn: (code: string, state: string) => void;
  googleReviewSignIn: (code: string, state: string) => void;
  confirmRegistration: (userId: string, token: string) => void;
  getPreferSourceType: () => void;

  getUserOutlookPreferences: () => void;
  saveUserPreferences: (preferences: UserEmailPreferences) => void;
  getEmailSyncFilter: () => void;
  saveEmailSyncFilter: (filter: EmailSyncFilter) => void;
  updateSignature: (value: string) => void;
  //gmail
  getUserGmailPreferences: () => void;
  saveGmailUserPreferences: (preferences: UserEmailPreferences) => void;
  getGmailSyncFilter: () => void;
  saveGmailSyncFilter: (filter: EmailSyncFilter) => void;
  updateGmailSignature: (value: string) => void;

  addTrustPilotUrl: (value: string, name: string) => void;
  addAmazonUrl: (value: string, name: string) => void;
  removeTrustPilotUrl: (value: string) => void;
  removeAmazonUrl: (value: string) => void;
  addEbayUser: (value: string, name: string) => void;
  removeEbayUser: (value: string) => void;
  updateUserWorkspaceDetails: (workspace: Workspace) => void;
  revokeEbayAccess: () => void;
  addGoogleReview: (value: string, name: string) => void;
  removeGoogleReview: (value: string) => void;
  revokeGoogleReviewAccess: () => void;

  updateSuscriptionExpiry: () => void;

  paymentUpdate: (result: PaymentUpdateResponse) => void;
  updateSubsciptionDetails: (sub: SubscriptionResoponse) => void;
  updateOutlookmailErrorForMailIdAlreadyInUse: (value: string) => void;
  updateGmailErrorForMailIdAlreadyInUse: (value: string) => void;
  updatemicrosoftCallBackStatus: (value: string) => void;
  updategoogleCallBackStatus: (value: string) => void;
}

export const useAuthStore = create<AuthState>()(
  devtools(
    persist(
      (set, get) => ({
        outlookmailErrorForMailIdAlreadyInUse: '',
        gmailErrorForMailIdAlreadyInUse: '',
        microsoftCallBackStatus: '',
        googleCallBackStatus: '',

        subscriptionExpired: false,
        isInitialized: true,
        isAuthenticated: false,
        isEbayAuthenticated: false,
        isGorgiasAuthenticated: false,
        isGoogleReviewAuthenticated: false,
        token: null,
        loading: false,
        refreshToken: null,
        errorText: null,
        resetPassError: null,
        registerError: null,
        microsoftError: null,
        userEmail: null,
        userName: null,
        accountProvider: null,
        preferSourceType: null,
        preferSourceTypeError: null,
        isTypeLoaded: false,
        previousSource: null,
        requestedLocation: '',
        emailSyncFilter: {
          copiesRecipients: '',
          recipients: '',
          senders: '',
          subject: '',
          tags: ''
        },
        outlookUserpreferences: {
          sendUsingSharedEmail: true,
          sharedEmail: '',
          signature: ''
        },
        signature: '',
        savingOutlookPreferences: false,
        savingEmailSyncFilter: false,

        gmailUserpreferences: {
          sendUsingSharedEmail: true,
          sharedEmail: '',
          signature: ''
        },
        gmailSignature: '',
        savingGmailPreferences: false,
        gmailSyncFilter: {
          copiesRecipients: '',
          recipients: '',
          senders: '',
          subject: '',
          tags: ''
        },
        savingGmailSyncFilter: false,
        user: null,
        updateSuscriptionExpiry: () => {
          const exDate = Date.parse(get().user.subscriptionExpiryDate);

          set({ subscriptionExpired: exDate < Date.now() });
        },
        updateSubsciptionDetails: async (sub: SubscriptionResoponse) => {
          const tmpuser = get().user;
          tmpuser.noOfIneractionsUsed = sub.noOfIneractionsUsed;
          tmpuser.noOfMaxInteractions = sub.noOfMaxInteractions;
          set({ user: tmpuser });
        },
        login: async (userName: string, password: string) => {
          var result = await AuthService.login(userName, password);

          if (result.status == 200) {
            const token = result.data.accessToken;
            const refreshToken = result.data.refreshToken;
            localStorage.setItem(
              StorageKeys.Token,
              JSON.stringify({
                token,
                refreshToken
              })
            );

            let decodedJWT = JSON.parse(window.atob(token.split('.')[1]));

            if (decodedJWT !== null) {
              useUsersStore.persist.clearStorage();
              useEmailStore.persist.clearStorage();
              useAmazonReviewStore.persist.clearStorage();
              useTrustpilotReviewStore.persist.clearStorage();
              useEbayReviewStore.persist.clearStorage();
              useGoogleReviewStore.persist.clearStorage();
              useDashboardStore.persist.clearStorage();
              useReviewDashboardStore.persist.clearStorage();
              useTemplateStore.persist.clearStorage();

              set({
                user: {
                  avatar: '/static/images/avatars/6.png',
                  email: decodedJWT.email,
                  name: decodedJWT.name,
                  jobtitle: decodedJWT.email,
                  id: decodedJWT.userId,
                  username: '',
                  location: '',
                  role: decodedJWT.role,
                  coverImg: '',
                  followers: '',
                  description: '',
                  posts: '8',
                  roles: decodedJWT.role.split(',').map((x) => {
                    x as UserRole;
                  }) as unknown as UserRole[],
                  isAdmin:
                    (
                      decodedJWT.role
                        .split(',')
                        .filter((value) => value === 'Admin') as string[]
                    ).length > 0,
                  workspaceId: decodedJWT.workspaceId,
                  workspaceName: decodedJWT.workspaceName,
                  isWorkspaceAdmin: decodedJWT.isWorkspaceAdmin === 'True',
                  isEbayAuthenticated:
                    decodedJWT.isEbayAuthenticated === 'True',
                  isGoogleReviewAuthenticated:
                    decodedJWT.isGoogleREviewAuthenticated === 'True',
                  accountProvider: decodedJWT.accountProvider,
                  subscriptionExpiryDate: decodedJWT.subscriptionExpiryDate,
                  plan: decodedJWT.plan,
                  noOfIneractionsUsed: decodedJWT.noOfIneractionsUsed,
                  noOfMaxInteractions: decodedJWT.noOfMaxInteractions,
                  isOutlookConfigured:
                    decodedJWT.isOutlookConfigured === 'True',
                  isGmailConfigured: decodedJWT.isGmailConfigured === 'True',
                  outlookEmail: decodedJWT.outlookEmail,
                  outlookSyncStarted: decodedJWT.outlookSyncStarted === 'True'
                }
              });
              set({
                isEbayAuthenticated: decodedJWT.isEbayAuthenticated === 'True'
              });
              set({
                isGoogleReviewAuthenticated:
                  decodedJWT.isGoogleReviewAuthenticated === 'True'
              });
              set({ isAuthenticated: true });

              set({ requestedLocation: '/dashboards/reviewanalytics' });
            }
            set({ loading: false });
          }
        },
        clearStores: async () => {
          try {
            useEmailStore.persist.clearStorage();

            set({ isAuthenticated: false });
            useAuthStore.persist.clearStorage();
            useUsersStore.persist.clearStorage();
            useEmailStore.persist.clearStorage();
            useAmazonReviewStore.persist.clearStorage();
            useTrustpilotReviewStore.persist.clearStorage();
            useEbayReviewStore.persist.clearStorage();
            useGoogleReviewStore.persist.clearStorage();
            useDashboardStore.persist.clearStorage();
            useReviewDashboardStore.persist.clearStorage();
            useTemplateStore.persist.clearStorage();
          } catch (error: any) {}
        },
        logout: async () => {
          try {
            useEmailStore.persist.clearStorage();
            AuthService.resetAuth();
            const refreshToken = get().refreshToken;
            set({ isAuthenticated: false });
            set({ subscriptionExpired: false });
            await AuthService.logout(refreshToken);
            useAuthStore.persist.clearStorage();
            useUsersStore.persist.clearStorage();
            useEmailStore.persist.clearStorage();
            useAmazonReviewStore.persist.clearStorage();
            useTrustpilotReviewStore.persist.clearStorage();
            useEbayReviewStore.persist.clearStorage();
            useGoogleReviewStore.persist.clearStorage();
            useDashboardStore.persist.clearStorage();
            useReviewDashboardStore.persist.clearStorage();
            useTemplateStore.persist.clearStorage();
          } catch (error: any) {}
        },
        setToken: (token, refreshToken) => {
          localStorage.setItem(
            StorageKeys.Token,
            JSON.stringify({
              token,
              refreshToken
            })
          );
        },

        confirmRegistration: async (userId: string, token: string) => {
          var result = await AuthService.confirmRegistration(userId, token);
          if (result.status == 200) {
            const token = result.data.accessToken;
            const refreshToken = result.data.refreshToken;
            localStorage.setItem(
              StorageKeys.Token,
              JSON.stringify({
                token,
                refreshToken
              })
            );
            let decodedJWT = JSON.parse(window.atob(token.split('.')[1]));

            if (decodedJWT !== null) {
              set({
                user: {
                  avatar: '/static/images/avatars/6.png',
                  email: decodedJWT.email,
                  name: decodedJWT.name,
                  jobtitle: decodedJWT.email,
                  id: decodedJWT.userId,
                  username: '',
                  location: '',
                  role: decodedJWT.role,
                  coverImg: '',
                  followers: '',
                  description: '',
                  posts: '8',
                  roles: decodedJWT.role.split(',').map((x) => {
                    x as UserRole;
                  }) as unknown as UserRole[],
                  isAdmin:
                    (
                      decodedJWT.role
                        .split(',')
                        .filter((value) => value === 'Admin') as string[]
                    ).length > 0,
                  workspaceId: decodedJWT.workspaceId,
                  workspaceName: decodedJWT.workspaceName,
                  isWorkspaceAdmin: decodedJWT.isWorkspaceAdmin === 'True',
                  subscriptionExpiryDate: decodedJWT.subscriptionExpiryDate,

                  isEbayAuthenticated:
                    decodedJWT.isEbayAuthenticated === 'True',
                  isGoogleReviewAuthenticated:
                    decodedJWT.isGoogleReviewAuthenticated === 'True',
                  accountProvider: decodedJWT.accountProvider,
                  plan: decodedJWT.plan,
                  noOfIneractionsUsed: decodedJWT.noOfIneractionsUsed,
                  noOfMaxInteractions: decodedJWT.noOfMaxInteractions,
                  isOutlookConfigured:
                    decodedJWT.isOutlookConfigured === 'True',
                  isGmailConfigured: decodedJWT.isGmailConfigured === 'True',
                  outlookEmail: decodedJWT.outlookEmail,
                  outlookSyncStarted: decodedJWT.outlookSyncStarted === 'True'
                }
              });
              set({
                isEbayAuthenticated: decodedJWT.isEbayAuthenticated === 'True'
              });
              set({
                isGoogleReviewAuthenticated:
                  decodedJWT.isGoogleReviewAuthenticated === 'True'
              });
              set({ isAuthenticated: true });

              set({ requestedLocation: '/dashboards/reviewanalytics' });
            }
            set({ loading: false });
          }
        },

        microsoftSignIn: async (
          code: string,
          state: string,
          alredyLoggedIn: boolean
        ) => {
          var result = await AuthService.microsoftSignIn(
            code,
            state,
            alredyLoggedIn
          );

          if (result.status == 200) {
            if (alredyLoggedIn) {
              set({ microsoftCallBackStatus: 'mailConfigured' });
              if ((result.data as any).indexOf('failed:', 0) >= 0) {
                set({
                  outlookmailErrorForMailIdAlreadyInUse: result.data as any
                });
              } else {
                set({
                  outlookmailErrorForMailIdAlreadyInUse: ''
                });
                let userTmp = get().user;
                userTmp.isOutlookConfigured = true;
                userTmp.outlookEmail = result.data as any;

                set({ user: userTmp });
              }
            } else {
              set({ microsoftCallBackStatus: 'login' });
              const token = result.data.accessToken;
              const refreshToken = result.data.refreshToken;
              localStorage.setItem(
                StorageKeys.Token,
                JSON.stringify({
                  token,
                  refreshToken
                })
              );
              let decodedJWT = JSON.parse(window.atob(token.split('.')[1]));

              if (decodedJWT !== null) {
                set({
                  user: {
                    avatar: '/static/images/avatars/6.png',
                    email: decodedJWT.email,
                    name: decodedJWT.name,
                    jobtitle: decodedJWT.email,
                    id: decodedJWT.userId,
                    username: '',
                    location: '',
                    role: decodedJWT.role,
                    coverImg: '',
                    followers: '',
                    description: '',
                    posts: '8',
                    roles: decodedJWT.role.split(',').map((x) => {
                      x as UserRole;
                    }) as unknown as UserRole[],
                    isAdmin:
                      (
                        decodedJWT.role
                          .split(',')
                          .filter((value) => value === 'Admin') as string[]
                      ).length > 0,
                    workspaceId: decodedJWT.workspaceId,
                    workspaceName: decodedJWT.workspaceName,
                    isWorkspaceAdmin: decodedJWT.isWorkspaceAdmin === 'True',
                    subscriptionExpiryDate: decodedJWT.subscriptionExpiryDate,

                    isEbayAuthenticated:
                      decodedJWT.isEbayAuthenticated === 'True',
                    isGoogleReviewAuthenticated:
                      decodedJWT.isGoogleReviewAuthenticated === 'True',
                    accountProvider: decodedJWT.accountProvider,
                    plan: decodedJWT.plan,
                    noOfIneractionsUsed: decodedJWT.noOfIneractionsUsed,
                    noOfMaxInteractions: decodedJWT.noOfMaxInteractions,
                    isOutlookConfigured:
                      decodedJWT.isOutlookConfigured === 'True',
                    isGmailConfigured: decodedJWT.isGmailConfigured === 'True',
                    outlookEmail: decodedJWT.outlookEmail,
                    outlookSyncStarted:
                      decodedJWT.outlookSyncStarted === 'True',
                    googleEmail: decodedJWT.googleEmail,
                    gmailSyncStarted: decodedJWT.gmailsyncStarted === 'True'
                  }
                });
                set({
                  isEbayAuthenticated: decodedJWT.isEbayAuthenticated === 'True'
                });
                set({
                  isGoogleReviewAuthenticated:
                    decodedJWT.isGoogleReviewAuthenticated === 'True'
                });

                set({ isAuthenticated: true });

                set({ requestedLocation: '/applications/mailbox/inbox' });
              }
              set({ loading: false });
            }
          }
        },
        gorgiasSignIn: async (code: string, state: string) => {
          let result = await AuthService.gorgiasSignIn(code, state);
          if (result.status == 200) {
            const token = result.data.accessToken;
            const refreshToken = result.data.refreshToken;
            localStorage.setItem(
              StorageKeys.Token,
              JSON.stringify({
                token,
                refreshToken
              })
            );
            let decodedJWT = JSON.parse(window.atob(token.split('.')[1]));

            if (decodedJWT !== null) {
              set({
                user: {
                  avatar: '/static/images/avatars/6.png',
                  email: decodedJWT.email,
                  name: decodedJWT.name,
                  jobtitle: decodedJWT.email,
                  id: decodedJWT.userId,
                  username: '',
                  location: '',
                  role: decodedJWT.role,
                  coverImg: '',
                  followers: '',
                  description: '',
                  posts: '8',
                  roles: decodedJWT.role.split(',').map((x) => {
                    x as UserRole;
                  }) as unknown as UserRole[],
                  isAdmin:
                    (
                      decodedJWT.role
                        .split(',')
                        .filter((value) => value === 'Admin') as string[]
                    ).length > 0,
                  workspaceId: decodedJWT.workspaceId,
                  workspaceName: decodedJWT.workspaceName,
                  isWorkspaceAdmin: decodedJWT.isWorkspaceAdmin === 'True',
                  subscriptionExpiryDate: decodedJWT.subscriptionExpiryDate,

                  isEbayAuthenticated:
                    decodedJWT.isGorgiasAuthenticated === 'True',
                  isGoogleReviewAuthenticated:
                    decodedJWT.isGoogleReviewAuthenticated === 'True',
                  accountProvider: decodedJWT.accountProvider,
                  plan: decodedJWT.plan,
                  noOfIneractionsUsed: decodedJWT.noOfIneractionsUsed,
                  noOfMaxInteractions: decodedJWT.noOfMaxInteractions,
                  isOutlookConfigured:
                    decodedJWT.isOutlookConfigured === 'True',
                  isGmailConfigured: decodedJWT.isGmailConfigured === 'True',
                  outlookEmail: decodedJWT.outlookEmail,
                  outlookSyncStarted: decodedJWT.outlookSyncStarted === 'True'
                }
              });
              set({
                isGorgiasAuthenticated:
                  decodedJWT.isGorgiasAuthenticated === 'True'
              });
              set({
                isGoogleReviewAuthenticated:
                  decodedJWT.isGoogleReviewAuthenticated === 'True'
              });

              set({ isAuthenticated: true });

              set({ requestedLocation: '/applications/mailbox/inbox' });
            }
            set({ loading: false });
          }
        },
        googleSignIn: async (
          code: string,
          state: string,
          alredyLoggedIn: boolean
        ) => {
          var result = await AuthService.googleSignIn(
            code,
            state,
            alredyLoggedIn
          );
          if (result.status == 200) {
            if (alredyLoggedIn) {
              set({ googleCallBackStatus: 'mailConfigured' });
              if ((result.data as any).indexOf('failed:', 0) >= 0) {
                set({
                  gmailErrorForMailIdAlreadyInUse: result.data as any
                });
              } else {
                set({
                  gmailErrorForMailIdAlreadyInUse: ''
                });
                let userTmp = get().user;
                userTmp.isGmailConfigured = true;
                userTmp.googleEmail = result.data as any;

                set({ user: userTmp });
              }
            } else {
              set({ googleCallBackStatus: 'login' });
              const token = result.data.accessToken;
              const refreshToken = result.data.refreshToken;
              localStorage.setItem(
                StorageKeys.Token,
                JSON.stringify({
                  token,
                  refreshToken
                })
              );
              let decodedJWT = JSON.parse(window.atob(token.split('.')[1]));

              if (decodedJWT !== null) {
                set({
                  user: {
                    avatar: '/static/images/avatars/6.png',
                    email: decodedJWT.email,
                    name: decodedJWT.name,
                    jobtitle: decodedJWT.email,
                    id: decodedJWT.userId,
                    username: '',
                    location: '',
                    role: decodedJWT.role,
                    coverImg: '',
                    followers: '',
                    description: '',
                    posts: '8',
                    roles: decodedJWT.role.split(',').map((x) => {
                      x as UserRole;
                    }) as unknown as UserRole[],
                    isAdmin:
                      (
                        decodedJWT.role
                          .split(',')
                          .filter((value) => value === 'Admin') as string[]
                      ).length > 0,
                    workspaceId: decodedJWT.workspaceId,
                    workspaceName: decodedJWT.workspaceName,
                    isWorkspaceAdmin: decodedJWT.isWorkspaceAdmin === 'True',
                    subscriptionExpiryDate: decodedJWT.subscriptionExpiryDate,

                    isEbayAuthenticated:
                      decodedJWT.isEbayAuthenticated === 'True',
                    isGoogleReviewAuthenticated:
                      decodedJWT.isGoogleReviewAuthenticated === 'True',
                    accountProvider: decodedJWT.accountProvider,
                    plan: decodedJWT.plan,
                    noOfIneractionsUsed: decodedJWT.noOfIneractionsUsed,
                    noOfMaxInteractions: decodedJWT.noOfMaxInteractions,
                    isOutlookConfigured:
                      decodedJWT.isOutlookConfigured === 'True',
                    isGmailConfigured: decodedJWT.isGmailConfigured === 'True',
                    googleEmail: decodedJWT.googleEmail,
                    gmailSyncStarted: decodedJWT.gmailsyncStarted === 'True',
                    outlookEmail: decodedJWT.outlookEmail,
                    outlookSyncStarted: decodedJWT.outlookSyncStarted === 'True'
                  }
                });
                set({
                  isEbayAuthenticated: decodedJWT.isEbayAuthenticated === 'True'
                });
                set({
                  isGoogleReviewAuthenticated:
                    decodedJWT.isGoogleReviewAuthenticated === 'True'
                });
                set({ isAuthenticated: true });

                set({ requestedLocation: '/applications/mailbox/inbox' });
              }
              set({ loading: false });
            }
          }
        },

        ebaySignIn: async () => {
          set({ isEbayAuthenticated: true });
        },
        googleReviewSignIn: async (code: string, state: string) => {
          set({ isGoogleReviewAuthenticated: true });
        },
        getPreferSourceType: async () => {
          var result = await AuthService.getPreferSourceType();
          set({ preferSourceType: result.data });
        },

        getUserOutlookPreferences: async () => {
          var result = await EmailService.getUserOutlookPreferences();
          set({ outlookUserpreferences: result.data });
        },
        saveUserPreferences: async (preferences: UserEmailPreferences) => {
          set({ savingOutlookPreferences: true });
          preferences.signature = get().outlookUserpreferences.signature;
          var result = await EmailService.saveUserOutlookPreferences(
            preferences
          );
          let userTmp = get().user;
          userTmp.outlookSyncStarted = true;
          set({ user: userTmp });
          set({ outlookUserpreferences: preferences });
          set({ savingOutlookPreferences: false });
        },
        getEmailSyncFilter: async () => {
          var result = await EmailService.getEmailSyncFilter();
          set({ emailSyncFilter: result.data });
        },
        saveEmailSyncFilter: async (filter: EmailSyncFilter) => {
          set({ savingEmailSyncFilter: true });
          var result = await EmailService.saveEmailSyncFilter(filter);
          set({ savingEmailSyncFilter: false });
        },
        updateSignature: (value: string) => {
          let preferences = get().outlookUserpreferences;
          preferences.signature = value;
          set({ outlookUserpreferences: preferences });
        },

        //gmail
        getUserGmailPreferences: async () => {
          var result = await GmailService.getUserGmailPreferences();
          set({ gmailUserpreferences: result.data });
        },
        saveGmailUserPreferences: async (preferences: UserEmailPreferences) => {
          set({ savingGmailPreferences: true });
          preferences.signature = get().gmailUserpreferences.signature;
          var result = await GmailService.saveUserGmailPreferences(preferences);
          set({ gmailUserpreferences: preferences });
          set({ savingGmailPreferences: false });
        },
        getGmailSyncFilter: async () => {
          var result = await GmailService.getEmailSyncFilter();
          set({ emailSyncFilter: result.data });
        },
        saveGmailSyncFilter: async (filter: EmailSyncFilter) => {
          set({ savingEmailSyncFilter: true });
          var result = await GmailService.saveEmailSyncFilter(filter);
          set({ savingEmailSyncFilter: false });
        },
        updateGmailSignature: (value: string) => {
          let preferences = get().gmailUserpreferences;
          preferences.signature = value;
          set({ gmailUserpreferences: preferences });
        },

        //#region reviews
        reviewsPreferences: [],
        trustpiloturls: [],
        amazonurls: [],
        ebayUsers: [],
        googleReviews: [],
        getReviewPreferences: async () => {
          set({ savingReviewPreferences: false });
          var result = await ReviewService.getUserWebsites();
          set({ reviewsPreferences: result.data });
          if (result.data.length > 0) {
            set({ trustpiloturls: [] });
            set({ amazonurls: [] });
            set({ ebayUsers: [] });
            set({ googleReviews: [] });
            const trustpilotResult = result.data.filter(
              (x) => x.type === 'trustpilot.com'
            );
            const amazonResult = result.data.filter(
              (x) => x.type === 'amazon.com'
            );
            const ebayResult = result.data.filter((x) => x.type === 'ebay');
            const googleReviewResult = result.data.filter(
              (x) => x.type === 'googleReview'
            );
            if (trustpilotResult.length > 0)
              set({ trustpiloturls: trustpilotResult });
            if (amazonResult.length > 0) set({ amazonurls: amazonResult });
            if (ebayResult.length > 0) set({ ebayUsers: ebayResult });
            if (googleReviewResult.length > 0)
              set({ googleReviews: googleReviewResult });
          }
        },
        saveReviewPreferences: async () => {
          let preferences: SaveWebsitesUrlsResponse = [];
          preferences.push(...get().trustpiloturls);
          preferences.push(...get().amazonurls);
          preferences.push(...get().ebayUsers);
          preferences.push(...get().googleReviews);
          set({ reviewsPreferences: preferences });
          set({ savingReviewPreferences: true });
          //var result = await ReviewService.saveWebsites(preferences);
          set({ savingReviewPreferences: false });
        },
        savingReviewPreferences: false,

        addTrustPilotUrl: (value: string, name: string) => {
          set({
            trustpiloturls: [
              ...get().trustpiloturls,
              { type: 'trustpilot.com', url: value, name: name }
            ]
          });
        },
        addAmazonUrl: (value: string, name: string) => {
          set({
            amazonurls: [
              ...get().amazonurls,
              { type: 'amazon.com', url: value, name: name }
            ]
          });
        },
        addEbayUser: (value: string, name: string) => {
          set({
            ebayUsers: [
              ...get().ebayUsers,
              { type: 'ebay', url: value, name: name }
            ]
          });
        },
        addGoogleReview: (value: string, name: string) => {
          set({
            googleReviews: [
              ...get().googleReviews,
              { type: 'googleReview', url: value + ',' + name, name: name }
            ]
          });
        },
        revokeEbayAccess: async () => {
          const result = await AuthService.revokeEbayAccess();
          if (result.status === 200) {
            let user = get().user;
            user.isEbayAuthenticated = false;
            set({ user: user });
            set({ isEbayAuthenticated: false });
          }
        },
        revokeGoogleReviewAccess: async () => {
          let user = get().user;
          set({ googleReviews: [] });
          user.isGoogleReviewAuthenticated = false;
          set({ user: user });
          set({ isGoogleReviewAuthenticated: false });
        },
        removeTrustPilotUrl: (value: string) => {
          const urls = get().trustpiloturls.filter((x) => x.url !== value);
          set({ trustpiloturls: urls });
        },
        removeAmazonUrl: (value: string) => {
          const urls = get().amazonurls.filter((x) => x.url !== value);
          set({ amazonurls: urls });
        },
        removeEbayUser: (value: string) => {
          const urls = get().ebayUsers.filter((x) => x.url !== value);
          set({ ebayUsers: urls });
        },
        removeGoogleReview: (value: string) => {
          const urls = get().googleReviews.filter((x) => x.url !== value);
          set({ googleReviews: urls });
        },
        updateUserWorkspaceDetails: (workspace: Workspace) => {
          let updatedUser = get().user;
          updatedUser.workspaceId = workspace.id;
          updatedUser.workspaceName = workspace.name;
          updatedUser.isWorkspaceAdmin =
            updatedUser.id === workspace.adminUserId;
          set({ user: updatedUser });
        },
        updateSavingReviewPreferences: (value: boolean) => {
          set({ savingReviewPreferences: value });
        },
        //#endregion

        paymentUpdate: async (result: PaymentUpdateResponse) => {
          let updatedUser = get().user;
          updatedUser.plan = result.plan;
          updatedUser.subscriptionExpiryDate = result.subscriptionExpiryDate;
          updatedUser.role = result.roles as UserRole;
          set({ user: updatedUser });
        },
        updateOutlookmailErrorForMailIdAlreadyInUse: (value: string) => {
          set({ outlookmailErrorForMailIdAlreadyInUse: value });
        },
        updateGmailErrorForMailIdAlreadyInUse: (value: string) => {
          set({ gmailErrorForMailIdAlreadyInUse: value });
        },
        updatemicrosoftCallBackStatus: (value: string) => {
          set({ microsoftCallBackStatus: value });
        },
        updategoogleCallBackStatus: (value: string) => {
          set({ googleCallBackStatus: value });
        }
      }),
      { name: 'AuthStore' }
    )
  )
);
